export default {
  name: "faq",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {}
};
